<template>
  <div class="container">
    <!-- <br /><br /> -->
<!-- <QrCodeScanner >   </QrCodeScanner> -->
    
    <div>
      <scan></scan>
      <!-- <v-quagga :onDetected="logIt" :readerSize="readerSize" :readerTypes="['ean_reader']" :aspectRatio="aspectRation"></v-quagga> -->
      {{EAN}}
    </div>

 
  </div>
</template>

<script>
/* import storageHelper from 'storage-helper'; */
import Vue from "vue";
import Scan from './1_Scan.vue';

//import VueQuagga from "./C_quagga.vue";

//Vue.use(VueQuagga);
// import QrCodeScanner from './QrCodeScanner';
// import HTMLscanner from "./HTML5.vue";
export default {
  name: 'Home',
  props: {
    msg: String,
  },
  data () {
    return {
      EAN:"",
      readerSize: {
        width: 200,
        height: 100
      },
      aspectRation:{
        min: 16/9,
        max: 16/9,
      },
      detecteds: []
    }
  },
  computed: {},
  methods: {
    onScan (decodedText, decodedResult) {
      // handle the message here :)
    },
     logIt (data) {
      if (data!=this.EAN){
      this.EAN = data
      console.log('detected', data)
      }
    }
  },
  components: {
    // QrCodeScanner,
    scan: Scan,
    // HTMLscanner
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}



</style>
