import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueOffline from 'vue-offline'

/* import axios from 'axios'; */

import Vue from 'vue'
import Vuex from 'vuex'
import VueQuagga from 'vue-quaggajs'

import App from './App.vue'
import router from './router'
// import store from './store'
import 'bootstrap/dist/css/bootstrap.css'

Vue.use(require('vue-moment'))

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Vue.use(VueQuagga);
Vue.use(VueOffline)
Vue.config.productionTip = false
Vue.use(Vuex)

Vue.use(VueQuagga)
new Vue({
  router,

  render: (h) => h(App)
}).$mount('#app')
