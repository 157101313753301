<template>
  <div>
    <b-card no-body class="overflow-hidden mx-auto" style="max-width: 1000px;">
      <b-row no-gutters>
        <b-col md="6">
          <b-card-img src="/api/get_image?filename=rebonjour.png" alt="Image" class="rounded-0"></b-card-img>
        </b-col>
        <b-col md="6">
          <b-card-body title=" 📸 Photos Linéaires  📸 ">

            <b-card-text>
              Bienvenue sur le module de chargement des photos d'éléments
            </b-card-text>

            <b-alert v-if="marche_jour !== null && type_releve === 'releve_du_jour' && perimetre_releve === null"
              show><b>Eléments à prendre en photo ce jour:</b> <br />{{ marche_jour }}</b-alert>

            <b-alert v-if="perimetre_releve !== null" show><b>Eléments à prendre en photo ce jour:</b> {{
              perimetre_releve }}</b-alert>

            <div v-if="site_status === false">
              Renseignez le nom de votre magasin

              <b-form-input type="text" v-model="searchSite" autocomplete="off" placeholder="Rechercher un site..."
                @input="performSearch" />

              <ul v-if="filteredSites.length">
                <li v-for="site in filteredSites" :key="site.site_ID" @click="selectSite(site)">
                  {{ site.HM_SM }} - {{ site.libelle_auchan }} - {{ site.code_ROSA }}
                </li>
              </ul>

              <div v-if="1 === 2">
                <b-form-input type="text" v-model="searchNom" placeholder="Sélectionner un marché"
                  @input="performNomenclatureSearch" />

                <ul v-if="filteredNomenclatures.length">
                  <li v-for="lvl in filteredNomenclatures" :key="lvl.APPLI_MARCHE">
                    {{ lvl.APPLI_ACTIVITE }} - {{ lvl.APPLI_RAYON }} - {{ lvl.APPLI_MARCHE }}
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="site_status === true">
              <div class="mb-2">
                Magasin sélectionné:
                <b-alert show variant="secondary"><b>{{ selected_site_format }} - {{ selected_code_rosa }} - {{
                    selected_site_label }}</b></b-alert>
              </div>

              <div class="image-uploader">
                <h2>Chargement des photos</h2>

                <div class="mb-2">
                  <b-form-file id="file-large" size="lg" @change="onFileChange"  
                  accept="image/*, .heic, .heif"              
                  multiple
                    placeholder="Sélectionnez les images" browse-text="Parcourir"></b-form-file>
                </div>

                <div class="mt-2" v-if="uploading === false" align-self="center">
                  <button @click="uploadImages" :disabled="!imageFiles.length">Upload</button>
                </div>

                <div class="mt-2" v-if="uploading === true" align-self="center">
                  <b-spinner label="Spinning"></b-spinner> Chargement en cours ... {{ successCount }} / {{ totalImages }}
                  photos - {{ progress }} %
                </div>

                <ul v-if="uploadStatus.length">
                  <li v-for="(status, index) in uploadStatus" :key="index">
                    {{ status }}
                  </li>
                </ul>
              </div>
            </div>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import Fuse from 'fuse.js';

export default {
  data() {
    return {
      imageFiles: [],
      uploadStatus: [],
      marche_jour: null,
      perimetre_releve: null,
      date_analyse: null,
      magasin_selectionne: null,
      type_releve: "releve_du_jour",
      options_type_releves: [
        { text: 'Releve du jour', value: 'releve_du_jour' },
        { text: 'Relevé personnalisé', value: 'releve_perso' },
      ],
      searchSite: '',
      searchNom: "",
      sites: [],
      sites_fields: [],
      filteredSites: [],
      fuse_sites: null,
      fuse_nomenclature: null,
      selected_code_rosa: null,
      selected_site_label: null,
      selected_site_format: null,
      site_status: false,
      uploading: false,
      nomenclature: [],
      nomenclature_fields: [],
      filteredNomenclatures: [],
      scope: "",
      id_campagne: "",
      progress: 0,
      successCount: 0,
      totalImages: 0
    };
  },
  methods: {
    async getSites() {
      const scope = this.scope
      if (scope == null) {

      }

      const path = `/api/sites?scope=${scope}`;
      try {
        const res = await axios.get(path);
        this.sites = res.data;
        this.sites_fields = ['Code\nCasino', 'HM_SM', 'Territoire', 'code_ROSA', 'libelle_auchan', 'Ville'];
        const options = {
          keys: this.sites_fields,
          threshold: 0.3,
        };
        this.fuse_sites = new Fuse(this.sites, options);
      } catch (error) {
        console.error(error);
      }
    },
    async getNomenclature() {
      const path = `/api/nomenclature`;
      try {
        const res = await axios.get(path);
        this.nomenclature = res.data;
        this.nomenclature_fields = ['APPLI_ACTIVITE', 'APPLI_MARCHE', 'APPLI_RAYON'];
        const options = {
          keys: this.nomenclature_fields,
          threshold: 0.3,
        };
        this.fuse_nomenclature = new Fuse(this.nomenclature, options);
      } catch (error) {
        console.error(error);
      }
    },
    async getMarcheJour() {
      const path = `/api/planning_releves`;
      try {
        const res = await axios.get(path);
        this.marche_jour = res.data['marche_cible'];
      } catch (error) {
        console.error(error);
      }
    },
    onFileChange(event) {
  const files = event.target.files;
  // Check if files is defined and has at least one file
  if (!files || files.length === 0) {
    this.uploadStatus = ['Aucune image valide sélectionnée'];
    return;
  }

  this.imageFiles = [];
  this.uploadStatus = [];

  for (let i = 0; i < files.length; i++) {
    // Log the type of each file for debugging purposes
    console.log(`File ${i}:`, files[i].type, files[i].name);

    // Accept standard image types, HEIC/HEIF formats, and extensions if MIME type is not reliable
    if (
      files[i].type.startsWith('image/') ||
      files[i].type === 'image/heic' ||
      files[i].type === 'image/heif' ||
      /\.(heic|heif)$/i.test(files[i].name) // Check file extension as a fallback
    ) {
      this.imageFiles.push(files[i]);
    } else {
      this.uploadStatus.push(`Le fichier ${files[i].name} n'est pas une image valide.`);
    }
  }

  if (this.imageFiles.length === 0) {
    this.uploadStatus.push('Aucune image valide sélectionnée');
  }
},
    async uploadImages() {
      if (!this.imageFiles.length) {
        this.uploadStatus.push('Aucune image sélectionnée');
        return;
      }

      this.uploadStatus = [];
      this.uploading = true;

      try {
        // Initialize a counter to track progress
        let successCount = 0;
        this.totalImages = this.imageFiles.length
        for (const file of this.imageFiles) {
          const formData = new FormData();
          formData.append('image', file);

          const response = await axios.post(
            '/api/upload?code_rosa=' + this.selected_code_rosa + "&marche_jour=" + this.perimetre_releve + "&date_analyse=" + this.date_analyse + "&id_campagne=" + this.id_campagne,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          console.log(response.data)
          // Update status with response message for the current file
          this.uploadStatus.push(`${file.name}: ${response.data[0].message}`);
          successCount++;
          this.successCount = successCount
          // Optionally update a progress indicator
          this.progress = Math.round((successCount / this.imageFiles.length) * 100);
        }

        // If needed, handle any additional logic after all uploads
        if (successCount === this.imageFiles.length) {
          this.uploadStatus.push('Toutes les images ont été téléchargées avec succès.');
        }

      } catch (error) {
        this.uploadStatus.push("Une erreur est survenue lors de l'upload");
        console.error('Erreur:', error);
      } finally {
        this.uploading = false;
        this.imageFiles = []; // Clear the image files after upload
      }
    },
    performSearch() {
      if (this.searchSite.trim() === '') {
        this.filteredSites = this.sites;
      } else {
        this.filteredSites = this.fuse_sites.search(this.searchSite).map(result => result.item);
      }
    },
    performNomenclatureSearch() {
      if (this.searchNom.trim() === '') {
        this.filteredNomenclatures = this.nomenclature;
      } else {
        this.filteredNomenclatures = this.fuse_nomenclature.search(this.searchNom).map(result => result.item);
      }
    },
    selectSite(site) {
      this.selected_code_rosa = site.code_ROSA;
      this.selected_site_label = site.libelle_auchan;
      this.selected_site_format = site.HM_SM;
      this.site_status = true;
    },
  },
  created() {
    this.getSites();
    this.getNomenclature();

    const queryString = window.location.search;
    console.log(queryString)
    const urlParams = new URLSearchParams(queryString);
    const perimetre_releve = urlParams.get('perimetre_releve');
    const date_analyse = urlParams.get('date_analyse');
    const scope = urlParams.get('scope');
    let id_campagne = urlParams.get('id_campagne');
    this.perimetre_releve = perimetre_releve;
    this.date_analyse = date_analyse;
    this.scope = scope;
    if (id_campagne == null) {
      id_campagne = 1
    }
    this.id_campagne = id_campagne;
    this.filteredSites = this.sites;
    console.log(scope)
  },
};
</script>

<style scoped>
/* General styling for the component */
div {
  font-family: Arial, sans-serif;
  color: #333;
}

/* Styling the input field */
b-form-input {
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling the list of sites */
ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  max-width: 500px;
}

li {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

li:hover {
  background-color: #f0f0f0;
}

/* Styling for the selected site information */
.mb-2 {
  font-size: 1.1em;
  margin-bottom: 20px;
}

/* Styling the image uploader section */
.image-uploader {
  margin-top: 20px;
  padding: 20px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 600px;
}

.image-uploader h2 {
  margin-bottom: 15px;
  font-size: 1.5em;
  color: #555;
}

.image-uploader input[type="file"] {
  display: block;
  margin-bottom: 10px;
}

.image-uploader button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.image-uploader button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.image-uploader button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Styling the upload status list */
.image-uploader ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.image-uploader ul li {
  padding: 8px;
  margin-bottom: 5px;
  background-color: #e9ecef;
  border-radius: 4px;
}
</style>
