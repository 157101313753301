import Vue from 'vue'
//import storageHelper from 'storage-helper'
import Router from 'vue-router'
import Home from '../components/0_Home.vue'
import RelevesLineaires from '../components/2_RelevesLineaires.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/releves_lineaires',
      name: 'RelevesLineaires',
      component: RelevesLineaires
    }
  ]
})

export default router
