<template>
  <div>
    <div id="container">
      <div id="qr-reader" style="position: relative"></div>
    </div>
  </div>
</template>
<script>
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';

export default {
  name: 'HTMLscanner',
  props: {
    qrbox: {
      type: Number,
      default: 250,
    },
    fps: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
  },
  components: {
    // QrCodeScanner,
    // scan: Scan,
  },
  methods: {
    onScanSuccess(decodedText, decodedResult) {
      this.$emit('result', decodedText, decodedResult);
    },
  },
  mounted() {
    const width = window.innerWidth;

    const formatsToSupport = [
      Html5QrcodeSupportedFormats.QR_CODE,
      Html5QrcodeSupportedFormats.UPC_A,
      Html5QrcodeSupportedFormats.UPC_E,
      Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
      Html5QrcodeSupportedFormats.EAN_13,
      Html5QrcodeSupportedFormats.EAN_8,
    ];
    const aspectRatio = 1.77777777777;
    const config = {
      formatsToSupport: formatsToSupport,
      fps: 10,
      // aspectRatio: aspectRatio,
      videoConstraints: {
        width: { ideal: 1920 },
        height: { ideal: 1080 },
        facingMode: 'environment',
        focusMode: 'continuous',
        advanced: [{ zoom: 2.0 }],
        useBarCodeDetectorIfSupported: true,
        experimentalFeatures: {
          useBarCodeDetectorIfSupported: true,
        },
      },
      qrbox: { width: width * 0.7, height: 200 },
    };

    const cameraConfig = {
      facingMode: 'environment',
    };

    const scanner = new Html5Qrcode('qr-reader', config);
    scanner.start(cameraConfig, config, this.onScanSuccess);

        // wait 2 seconds to guarantee the camera has already started to apply the focus mode and zoom...
      setTimeout(function () {
        scanner.applyVideoConstraints(config);
        
      }, 2000);
  },
};
</script>
<style scoped>
</style>